import { useContext, useState, type FunctionComponent } from "react";
import { PageHeading } from "../../components/page/page";
import { AppContext } from "../../utils/appcontext";
import LoadIndicator from "../../components/loadindicator";

export const Ziegel899Page: FunctionComponent = () => {
    const appContext = useContext(AppContext);
    const [state, setState] = useState();

    if (!appContext) {
        throw new Error(`No context`);
    }
    return (
        <div className="page">
            <PageHeading title="Ziegel 899" />
            <LoadIndicator />
        </div>
    );
};
