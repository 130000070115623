import React, {
    FunctionComponent,
    useCallback,
    useMemo,
    useState,
} from "react";
import { Redirect, Route, Switch } from "react-router";
import { Sidebar } from "./sidebar";
import "./app.scss";
import { SettingsPage } from "../pages/settings/settings";
import { AppContext, AppContextType } from "../utils/appcontext";
import { PublishingStatusPage } from "../pages/publishingstatus/publishingstatus";
import { AppStorage } from "../utils/storage/appstorage";
import { ApiClient } from "../utils/apiclient/apiclient";
import { BrowserRouter } from "react-router-dom";
import messages from "../lang/messages";
import { IntlProvider } from "react-intl";
import { Topbar } from "./topbar";
import { QuantityPage } from "../pages/quantity/quantitypage";
import ListingDetailPage from "../pages/listingdetail/listingdetailpage";
import { UploadQuantityPage } from "../pages/uploadquantity/uploadquantitypage";
import IsbnPublisherPage from "../pages/isbnprefix/isbnpublisherspage";
import IsbnPrefixPage from "../pages/isbnprefix/isbnprefixpage";
import LostQuantityPage from "../pages/lostquantity/lostquantity.page";
import { TagsPage } from "../pages/tags/tags.page";
import BlacklistPage from "../pages/blacklist/blacklist.page";
import NotificationsPage from "../pages/notifications/notifications.page";
import IsbnToolsPage from "../pages/isbntools/isbntools.page";
import { TooManyOffersPage } from "../pages/toomanyoffers/toomanyoffers.page";
import { NotesPage } from "../pages/notes/notes.page";
import { Ziegel899Page } from "../pages/ziegel-899/ziegel-899.page";

type Props = {
    appStorage: AppStorage;
    client: ApiClient;
    inialLang: string;
};

export const App: FunctionComponent<Props> = ({
    appStorage,
    client,
    inialLang,
}) => {
    const [lang, setLang] = useState<string>(inialLang);

    const changeLang = useCallback(
        (newLang: string): void => {
            setLang(newLang);
            // @TODO: notofication about error
            appStorage.setLang(newLang).catch(console.log);
        },
        [setLang, appStorage]
    );

    const context: AppContextType = useMemo<AppContextType>(
        () => ({
            client,
            storage: appStorage,
            currentLang: lang,
            changeLang,
        }),
        [lang, appStorage, client, changeLang]
    );

    const hasAuth = Boolean(client.getAuth());
    console.log("Auth");
    console.log(hasAuth);

    return (
        <AppContext.Provider value={context}>
            <IntlProvider locale={lang} messages={messages[lang]}>
                <BrowserRouter>
                    <div id="ob">
                        <Sidebar />
                        <div className="main">
                            <Topbar />
                            <Switch>
                                <Route
                                    path={"/settings"}
                                    component={SettingsPage}
                                    exact={true}
                                    key="settings"
                                />
                                <Route
                                    key="approutes"
                                    render={() => (
                                        <>
                                            {console.log(
                                                "Rendering app routes"
                                            )}
                                            {!Boolean(
                                                context.client.getAuth()
                                            ) ? (
                                                <Redirect to={"/settings"} />
                                            ) : (
                                                <Switch>
                                                    <Route
                                                        path={"/"}
                                                        exact
                                                        key="Home"
                                                    >
                                                        <h1>Home</h1>
                                                    </Route>
                                                    <Route
                                                        path={
                                                            "/publishing_status"
                                                        }
                                                        key={
                                                            "Publishing status"
                                                        }
                                                        component={
                                                            PublishingStatusPage
                                                        }
                                                    />
                                                    <Route
                                                        path={
                                                            "/amazonshop/quantity/:slug"
                                                        }
                                                        key={
                                                            "AmazonshopQuantity"
                                                        }
                                                        component={QuantityPage}
                                                    />
                                                    <Route
                                                        path={
                                                            "/amazonshop/sku/:sku"
                                                        }
                                                        key={
                                                            "ListingDetailPage"
                                                        }
                                                        component={
                                                            ListingDetailPage
                                                        }
                                                    />
                                                    <Route
                                                        path={
                                                            "/upload/quantity"
                                                        }
                                                        key={
                                                            "UploadQuantityPage"
                                                        }
                                                        component={
                                                            UploadQuantityPage
                                                        }
                                                    />
                                                    <Route
                                                        path={"/isbnpublishers"}
                                                        key={
                                                            "IsbnPublisherPage"
                                                        }
                                                        component={
                                                            IsbnPublisherPage
                                                        }
                                                    />
                                                    <Route
                                                        path={
                                                            "/isbnprefix/:prefix"
                                                        }
                                                        key={"IsbnPrefixPage"}
                                                        component={
                                                            IsbnPrefixPage
                                                        }
                                                    />
                                                    <Route
                                                        path={
                                                            "/lostquantity/:skus"
                                                        }
                                                        key={"LostQuantityPage"}
                                                        component={
                                                            LostQuantityPage
                                                        }
                                                    />
                                                    <Route
                                                        path={"/tags"}
                                                        key={"TagsPage"}
                                                        component={TagsPage}
                                                    />
                                                    <Route
                                                        path={"/blacklist"}
                                                        key={"BlacklistPage"}
                                                        component={
                                                            BlacklistPage
                                                        }
                                                    />
                                                    <Route
                                                        path={"/notes"}
                                                        key={"Notes"}
                                                        component={NotesPage}
                                                    />
                                                    <Route
                                                        path={"/notifications"}
                                                        key={
                                                            "NotificationsPage"
                                                        }
                                                        component={
                                                            NotificationsPage
                                                        }
                                                    />
                                                    <Route
                                                        path={"/isbntools"}
                                                        key={"IsbnToolsPage"}
                                                        component={
                                                            IsbnToolsPage
                                                        }
                                                    />
                                                    <Route
                                                        path={"/toomanyoffers"}
                                                        key={
                                                            "TooManyOffersPage"
                                                        }
                                                        component={
                                                            TooManyOffersPage
                                                        }
                                                    />
                                                    <Route
                                                        path={"/ziegel-899"}
                                                        key="ziegel899"
                                                        component={
                                                            Ziegel899Page
                                                        }
                                                    />
                                                </Switch>
                                            )}
                                        </>
                                    )}
                                />
                            </Switch>
                        </div>
                    </div>
                </BrowserRouter>
            </IntlProvider>
        </AppContext.Provider>
    );
};
