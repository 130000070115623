import { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { AmazonshopQuantityDto } from "@ozibooks/server/dist/modules/amazon/dto/amazonshopquantity.dto";
import { PublishingStatusEntity } from "@ozibooks/server/dist/modules/publishingstatus/entities/publishingstatus.entity";
import { CreateQuantityUploadDto } from "@ozibooks/server/dist/modules/upload/dto/createquantityupload.dto";
import { AuthType } from "../../interfaces/auth";
import {
    BadRequestException,
    ForbiddenException,
    GatewayTimeoutException,
    HttpException,
    NetworkException,
    NotFoundException,
    ServerErrorException,
    TimeOutException,
    UnauthorizedException,
    UnknownClientException,
} from "./exceptions";
import { Amazonshop } from "@ozibooks/server/dist/modules/amazon/entities/amazonshop.entity";
import { UploadQuantityEntity } from "@ozibooks/server/dist/modules/upload/entities/uploadquantity.entity";
import { UploadFileEntity } from "@ozibooks/server/dist/modules/upload/entities/uploadfile.entity";
import {
    UploadFileDestination,
    UploadFileUploadType,
} from "@ozibooks/server/dist/modules/upload/types";
import { IsbnPUblisherCategory } from "@ozibooks/server/dist/modules/isbnprefix/types/category";
import { IsbnPublisherEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnpublisher.entity";
import UpdateIsbnPublisherDto from "@ozibooks/server/dist/modules/isbnprefix/dto/updateisbnpublisher.dto";
import { IsbnPrefixEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnprefix.entity";
import { TagEntity } from "@ozibooks/server/dist/modules/tags/entities/tag.entity";
import { ProductBlacklistEntity } from "@ozibooks/server/dist/modules/product/entities/blacklist.entity";
import NotificationEntity from "@ozibooks/server/dist/modules/notification/entities/notification.entity";
import { AvaWebUpdateResponse } from "@ozibooks/server/dist/modules/ava/types";
import { BalmerWebUpdateResponse } from "@ozibooks/server/dist/modules/balmer/types";
import { AmazonDataCompetitivePricing } from "@ozibooks/server/dist/modules/amazon/entities/amazondatacompetitive.entity";
import { AmazonDataLowestEntity } from "@ozibooks/server/dist/modules/amazon/entities/amazondatalowest.entity";
import { NoteEntity } from "@ozibooks/server/dist/modules/notes/note.entity";

export class ApiClient {
    private auth: AuthType | undefined;

    readonly publishingStatus = {
        getAll: async (): Promise<PublishingStatusEntity[]> => {
            return await this.get<PublishingStatusEntity[]>(
                "publishingstatuses"
            );
        },
        setOrderable: async (id: number): Promise<PublishingStatusEntity> => {
            return await this.put<PublishingStatusEntity>(
                `publishingstatuses/${id}/orderable`,
                null
            );
        },
        setNotOrderable: async (
            id: number
        ): Promise<PublishingStatusEntity> => {
            return await this.put<PublishingStatusEntity>(
                `publishingstatuses/${id}/notorderable`,
                null
            );
        },
        setOrderableStarred: async (
            id: number
        ): Promise<PublishingStatusEntity> => {
            return await this.put<PublishingStatusEntity>(
                `publishingstatuses/${id}/orderablestarred`,
                null
            );
        },
        setNotOrderableStarred: async (
            id: number
        ): Promise<PublishingStatusEntity> => {
            return await this.put<PublishingStatusEntity>(
                `publishingstatuses/${id}/notorderablestarred`,
                null
            );
        },
    };

    readonly amazonshop = {
        quantity: {
            zero: async (): Promise<AmazonshopQuantityDto[]> => {
                return await this.get<AmazonshopQuantityDto[]>(
                    "amazonshop/quantity/zero"
                );
            },
            high: async (): Promise<AmazonshopQuantityDto[]> => {
                return await this.get<AmazonshopQuantityDto[]>(
                    "amazonshop/quantity/high"
                );
            },
            low: async (): Promise<AmazonshopQuantityDto[]> => {
                return await this.get<AmazonshopQuantityDto[]>(
                    "amazonshop/quantity/low"
                );
            },
            critical: async (): Promise<AmazonshopQuantityDto[]> => {
                return await this.get<AmazonshopQuantityDto[]>(
                    "amazonshop/quantity/critical"
                );
            },
            deactivable: async (): Promise<Amazonshop[]> => {
                return await this.get<Amazonshop[]>(
                    "amazonshop/quantity/deactivable"
                );
            },
            activable: async (): Promise<Amazonshop[]> => {
                return await this.get<Amazonshop[]>(
                    "amazonshop/quantity/activable"
                );
            },
        },
        sku: async (sku: string): Promise<Amazonshop> => {
            return this.get<Amazonshop>("amazonshop/sku/" + sku);
        },
        getLostQuantityItems: async (skus: string[]): Promise<Amazonshop[]> => {
            return this.get<Amazonshop[]>(
                "amazonshop/lostqunatity/" + skus.join(",")
            );
        },
        deactivateLostQuantity: async (skus: string[]): Promise<void> => {
            await this.post(
                "amazonshop/lostqunatity/deactivate/" + skus.join(","),
                undefined
            );
        },
        tooManyOffers: async (): Promise<Amazonshop[]> => {
            return await this.get("amazonshop/quantity/toomanyoffers");
        },
        checkCompetitivePricing: async (
            asin: string
        ): Promise<AmazonDataCompetitivePricing> => {
            return await this.post<AmazonDataCompetitivePricing>(
                "amazonshop/api/competitive/" + asin,
                undefined
            );
        },
        checkLowestOffers: async (
            asin: string
        ): Promise<AmazonDataLowestEntity> => {
            return await this.post<AmazonDataLowestEntity>(
                "amazonshop/api/lowest/" + asin,
                undefined
            );
        },
    };

    readonly upload = {
        quantity: {
            create: async (data: CreateQuantityUploadDto[]): Promise<void> => {
                await this.post("upload/quantity", data);
            },
            update: async (data: CreateQuantityUploadDto[]): Promise<void> => {
                await this.put("upload/quantity", data);
            },
            getAll: async (): Promise<UploadQuantityEntity[]> => {
                return await this.get<UploadQuantityEntity[]>(
                    "upload/quantity"
                );
            },
            delete: async (skus: string[]): Promise<void> => {
                await this.delete("upload/quantity/", skus);
            },
            generate: async (): Promise<UploadFileEntity[]> => {
                return await this.post("upload/quantity/generate", undefined);
            },
        },
        files: {
            getAll: async (
                type?: UploadFileUploadType,
                destination?: UploadFileDestination,
                uploaded?: 0 | 1
            ): Promise<UploadFileEntity[]> => {
                const params: string[] = [];
                if (type) {
                    params.push(`upload_type=${type}`);
                }

                if (destination) {
                    params.push(`destination=${destination}`);
                }

                if (uploaded !== undefined) {
                    params.push(`uploaded=${uploaded}`);
                }

                let url = "upload/files";
                if (params.length) {
                    url += "?" + params.join("&");
                }

                return await this.get<UploadFileEntity[]>(url);
            },
            markAsDownloaded: async (id: number): Promise<UploadFileEntity> => {
                return await this.put<UploadFileEntity>(
                    "upload/files/" + id + "/1",
                    undefined
                );
            },

            downloadFile: async (id: number): Promise<Blob> => {
                const res = await this.axios.get<Blob>(
                    this.getUrl("upload/files/download/" + id),
                    { ...this.getRequestConfig(), responseType: "blob" }
                );

                return res.data;
            },
        },
    };

    readonly isbnPrefix = {
        getAll: async (
            page?: number,
            ignored?: 0 | 1,
            used_only?: 0 | 1,
            name?: string,
            category?: IsbnPUblisherCategory | "*"
        ): Promise<IsbnPublisherEntity[]> => {
            const params = new URLSearchParams();

            if (typeof page === "number") {
                params.set("page", String(page));
            }

            if (typeof ignored === "number") {
                params.set("ignored", `${ignored}`);
            }

            if (typeof used_only === "number") {
                params.set("used_only", `${used_only}`);
            }

            if (name) {
                params.set("name", name);
            }

            if (category) {
                params.set("category", category);
            }

            return await this.get<IsbnPublisherEntity[]>(
                "isbnprefix/?" + params.toString()
            );
        },
        updatePublisher: async (
            data: UpdateIsbnPublisherDto
        ): Promise<IsbnPublisherEntity> => {
            return await this.put<IsbnPublisherEntity>("isbnprefix", data);
        },
        getPrefix: async (
            prefix13: string,
            force: boolean = false
        ): Promise<IsbnPrefixEntity | undefined> => {
            const url =
                `isbnprefix/prefix/${prefix13}` + (force ? "?force=1" : "");
            const ret = await this.get<IsbnPrefixEntity>(url);
            return ret ? ret : undefined;
        },
        starPrefixes: async (prefixes13: string[]): Promise<void> => {
            await this.put("isbnprefix/star/" + prefixes13.join(","), null);
        },
        unstarPrefixes: async (prefixes13: string[]): Promise<void> => {
            await this.put("isbnprefix/unstar/" + prefixes13.join(","), null);
        },
        setPrefixesAsIgnored: async (prefixes13: string[]): Promise<void> => {
            await this.put("isbnprefix/ignore/" + prefixes13.join(","), null);
        },
        setPrefixesAsNotIgnored: async (
            prefixes13: string[]
        ): Promise<void> => {
            await this.put("isbnprefix/unignore/" + prefixes13.join(","), null);
        },
        setPrefixesAsUsedOnly: async (prefixes13: string[]): Promise<void> => {
            await this.put("isbnprefix/used/" + prefixes13.join(","), null);
        },
        unsetPrefixesAsUsedOnly: async (
            prefixes13: string[]
        ): Promise<void> => {
            await this.put("isbnprefix/unused/" + prefixes13.join(","), null);
        },
    };

    readonly tags = {
        getAll: async (): Promise<TagEntity[]> => {
            return await this.get<TagEntity[]>("tags");
        },
        createTag: async (name: string, color?: string): Promise<TagEntity> => {
            return await this.post<TagEntity>("tags", { name, color });
        },
        updateTag: async (
            id: number,
            name: string,
            color?: string
        ): Promise<void> => {
            await this.put("tags", { id, name, color });
        },

        updateProductTags: async (
            product_id: string,
            tags: number[]
        ): Promise<void> => {
            await this.put("tags/product", { product_id, tags });
        },

        addTags: async (product_id: string[], tags: number[]) => {
            await this.put("tags/add", { product_id, tags });
        },

        removeTags: async (product_id: string[], tags: number[]) => {
            await this.put("tags/remove", { product_id, tags });
        },
    };

    readonly blacklist = {
        getAll: async (
            page: number = 1,
            search?: string
        ): Promise<ProductBlacklistEntity[]> => {
            const params = new URLSearchParams();
            params.set("page", String(page));
            if (search) {
                params.set("search", search);
            }

            return await this.get<ProductBlacklistEntity[]>(
                "blacklist/?" + params.toString()
            );
        },
        create: async (
            product_id: string,
            reason: string
        ): Promise<ProductBlacklistEntity> => {
            return await this.post<ProductBlacklistEntity>("blacklist", {
                product_id,
                reason,
            });
        },
        update: async (product_id: string, reason: string): Promise<void> => {
            await this.put("blacklist", { product_id, reason });
        },
        delete: async (product_id: string): Promise<void> => {
            await this.delete("blacklist/" + product_id);
        },
    };

    readonly notifications = {
        getAll: async (
            page: number = 1,
            read: boolean = false
        ): Promise<NotificationEntity[]> => {
            const params = new URLSearchParams();
            params.set("page", String(page));
            if (read) {
                params.set("read", "1");
            }

            return await this.get<NotificationEntity[]>(
                "notifications/?" + params.toString()
            );
        },
        markAsRead: async (ids: number[]) => {
            await this.put("notifications/read/" + ids.join(","), null);
        },
        markAsUnread: async (ids: number[]) => {
            await this.put("notifications/unread/" + ids.join(","), null);
        },
    };

    readonly avaWeb = {
        update: async (product_id: string): Promise<AvaWebUpdateResponse> => {
            return await this.post<AvaWebUpdateResponse>(
                "awaweb/update/" + product_id,
                {}
            );
        },
    };

    readonly balmerWeb = {
        update: async (
            product_id: string
        ): Promise<BalmerWebUpdateResponse> => {
            return await this.post<BalmerWebUpdateResponse>(
                "balmerweb/update/" + product_id,
                {}
            );
        },
    };

    readonly notes = {
        create: async (
            title: string,
            text: string
        ): Promise<NoteEntity | null> => {
            return this.post<NoteEntity | null>("notes", { title, text });
        },

        getAll: async (
            page = 0,
            search = "",
            trash = false
        ): Promise<NoteEntity[]> => {
            const params: Record<string, string> = {};
            let url = "notes";

            if (trash) {
                params.trash = "true";
            }

            if (page > 0) {
                params.page = String(page);
            }

            if (search) {
                params.search = search;
            }

            if (Object.keys(params).length) {
                const tmp = new URLSearchParams(params);
                url += "?" + tmp.toString();
            }

            return this.get(url);
        },

        delete: async (id: number): Promise<void> => {
            this.delete(`notes/${id}`);
        },
        update: async (
            id: number,
            title: string,
            text: string
        ): Promise<NoteEntity> => {
            return this.put<NoteEntity>(`notes/${id}`, { title, text });
        },

        undelete: async (id: number): Promise<void> => {
            await this.patch("notes/undelete/" + id, {});
        },
    };

    constructor(private axios: AxiosInstance) {}

    private async get<T>(url: string): Promise<T> {
        try {
            const res = await this.axios.get<T>(
                this.getUrl(url),
                this.getRequestConfig()
            );

            return res.data;
        } catch (e: any) {
            throw ApiClient.handleError(e);
        }
    }

    private async post<T>(url: string, data: any): Promise<T> {
        try {
            const res = await this.axios.post<T>(
                this.getUrl(url),
                data,
                this.getRequestConfig()
            );

            return res.data;
        } catch (e: any) {
            throw ApiClient.handleError(e);
        }
    }

    private async delete(url: string, data?: any) {
        return await this.axios.delete(this.getUrl(url), {
            ...this.getRequestConfig(),
            data,
        });
    }

    private async put<T>(url: string, data: any): Promise<T> {
        try {
            const res = await this.axios.put<T>(
                this.getUrl(url),
                data,
                this.getRequestConfig()
            );

            return res.data;
        } catch (e: any) {
            throw ApiClient.handleError(e);
        }
    }

    private async patch<T>(url: string, data: any): Promise<T> {
        try {
            const res = await this.axios.patch<T>(
                this.getUrl(url),
                data,
                this.getRequestConfig()
            );

            return res.data;
        } catch (e: any) {
            throw ApiClient.handleError(e);
        }
    }

    setAuth(data: AuthType) {
        if (!data.host || !data.apikey) {
            throw new Error("Missing host or api key");
        }

        this.auth = data;
    }

    getAuth(): AuthType | undefined {
        return this.auth;
    }

    private getUrl(partialUrl: string): string {
        if (!this.auth) {
            throw new Error("Missing authentication data");
        }

        let url: string;
        if (!this.auth.host.includes("http")) {
            url = `http://${this.auth.host}`;
        } else {
            url = this.auth.host;
        }

        if (url[url.length - 1] === "/") {
            url = url.slice(0, -1);
        }

        if (this.auth.port) {
            url += `:${this.auth.port}`;
        }

        return `${url}/${partialUrl}`;
    }

    private getRequestConfig(): AxiosRequestConfig {
        if (!this.auth) {
            throw new Error("Missing authentication data");
        }

        return {
            headers: {
                API_KEY: this.auth.apikey,
            },
            timeout: 5 * 60 * 1000,
        };
    }

    static handleError(err: any) {
        const originalMsg: string = err?.message || "";

        if (!isAxiosError(err)) {
            return new UnknownClientException(originalMsg);
        }

        if (err.message.includes("timeout of")) {
            return new TimeOutException();
        }

        if (err.message.includes("Network Error")) {
            return new NetworkException();
        }

        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 401:
                    // UnauthorizedException
                    return new UnauthorizedException();
                case 403:
                    // Forbidden
                    return new ForbiddenException();
                case 404:
                    return new NotFoundException();
                case 400:
                    // bad request
                    return new BadRequestException();
                case 500:
                    // server error
                    return new ServerErrorException();
                case 504:
                    return new GatewayTimeoutException();
                default:
                    return new HttpException(
                        err.response.statusText,
                        err.response.status
                    );
            }
        }

        const tmp = new UnknownClientException(err.message);
        if (err.toJSON) {
            tmp.debugData = JSON.stringify(err.toJSON());
        }

        return tmp;
    }
}

const isAxiosError = (err: unknown): err is AxiosError => {
    return typeof err === "object" && (err as any)?.isAxiosError === true;
};
